
export const QS_ABOUT_PAGE_DATA = [
    {
        title: 'We wanted to make getting A*- A in IGCSE fool proof and stress free. ',
        super: 'About QuickSense',
        spacing: 'pt-5 pb-3',
        content: [
            'Studying and exams can bring a lot of feelings of stress and anxiety. It\'s hard to know when to start studying, what to study and even how to study — especially because this would probably be your first set of external exams.',
            'You want to learn and get good grades for your future but it can be a bit overwhelming with 5+ subjects to study for all at once.',
            'What happens most of the time is you end up not doing anything, feeling guilty about it and end up cramming before the exam - the exact opposite of what everyone tells you to do! Plus, cramming means you just forget everything you learnt right after the exam and have to re-learn everything for next year — except now it\'s much harder.',
            'We felt the exact same way when were studying and it\'s the main reason we created QuickSense. '
        ]
    },
    {
        title: 'Everyone keeps using the old school learning methods we know are inefficient ',
        super: 'Utilise the latest research',
        background: 'bg-light-green',
        spacing: 'pt-4 pb-3',
        content: [
            'The best methods and techniques of learning math based on neuroscience have been known for decades and have been proven time and time again by many studies. But no one seems to use these them!',
            'We\'re not saying traditional methods are bad — they do get some students A*- As and we can\'t argue with that. What we\'re saying is they are inefficient and waste a lot of time (and money). They can cause unnecessary stress and can cause you to miss out and fall behind.',
            'Part of the reason we believe is that the more effective methods can be quite hard to implement — it takes a lot of planning and organisation to do. ',
            'But with technology this has become much more viable — algorithms can do the background thinking, planning and organisation. It takes a lot of time, money and effort to set up (It took us several years!) but once done, it\'s much easier for us to scale this for everyone '
        ]
    },
    {
        title: 'We made something we wish we had during school ',
        background: 'bg-light-blue',
        spacing: 'pt-4 pb-3',
        content: [
            'For our Founder and CEO Vivek, QuickSense is a deeply personal product — it was born from his own experience in school and IGCSE.',
            'Here is what he had to say: <br/>',
            '<em>I failed maths. I think I was the only one in my class — it was embarrassing. It\'s not like I was a bad student, I got decent grades in all my other subjects. I studied a lot, but something didn\'t work. </em>',
            '<em>I now know what that something was. It was the way I was studying. When I didn\'t know how to do a math problem I approached it like my other subjects — tried to memorise the steps without actually understanding how to do it. I think it\'s because when I couldn\'t figure out how to get to the right answer I just moved on to doing the questions I knew I could do.  It was easier and in my head, and I was still putting in the work and \'studying\'. </em>',
            '<em>Fast forward a few years, I took a first-year psychology paper in university. There was a module on \'how we learn\' and it blew my goddamn mind. It went over these widely accepted techniques and methods like the forgetting curve, the spacing effect, the testing effect and many others (see our How It Works page for details) that people could use to make learning way more effective. I was hooked, I read everything I could about learning and even changed my major to Cognitive Psychology with a focus on Learning Science. </em>',
            '<em>It was crazy to me that these methods weren\'t used in school or university! So I took matters into my own hands and started my own tutoring company that would use these psychological and neuroscientific methods. </em>',
            '<em>It actually worked out amazingly, almost all of our students got A*- As in most of their subjects and they did it without having to waste hours and hours doing inefficient study and being stressed out. But this didn\'t scale. Because we had to manually implement a lot of these techniques, our tutors could work only with a few students at a time.</em>',
            '<em>I started looking for scalable solutions online and couldn\'t really find anything that worked — they had some parts but not all. So I managed to convince two of my friends (Vinny and Hamish) to become co-founders with me and build our own learning platform from scratch. We thought it would take 6 months. It ended up taking 4 years. But it was worth it. </em>',
            '<em>Through the process, we hired and consulted with PhD\'s, teachers, school principals, artificial intelligence programmers and even game developers. It wasn\'t easy, but we wanted to make something amazing. </em>',
            '<em>So now we have QuickSense which is focused on maths (my white whale) — the culmination of all our learnings so far, the most effective and efficient way to learn IGCSE maths in the world. </em>',
            '<em>It is so satisfying to see students using QuickSense to ace their exams without the stress or anxiety that is associated with learning. It kind of makes me a bit jealous — I wish I had this when I was doing IG maths. </em>',
            '— Vivek Kumar, Co-Founder and CEO of QuickSense. ',
        ]
    }
]