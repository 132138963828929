import React from 'react'
import { Link } from 'gatsby'

//components
import SEO from '../components/SEO/seo'
import Col from 'react-bootstrap/Col'
import Layout from '../components/Layout/layout'
import Section from '../components/Section/section'
import GetStarted from  '../components/GetStarted/getStarted'

// utils
import {QS_ABOUT_PAGE_DATA} from '../utils/pages/qsAboutPageData'

class AboutPage extends React.Component {
    render(){
        return <>
            <SEO title='About Us'/>
            <Layout classes='fw-landing-page'>
                {
                    QS_ABOUT_PAGE_DATA.map((section, index) => (
                        <Section
                            animation={false}
                            key={index} 
                            spacing={section.spacing}
                            background={section.background}
                            title={section.title}
                            titleClasses={section.titleClasses}
                            superTitle={section.super}
                            content={section.content}
                        >
                        </Section>
                    ))
                }
                <Section spacing={'pt-4 pb-3'} background={'bg-light-green'} center>
                    <Col className='col-lg-8' as='section' lg={8}>
                        <h2 className={'h1 mb-4 '}>Learn more about us</h2>
                        <p>You can find out more about the methods and techniques we use on our <Link to={'/how-it-works'}><strong>How It Works page.</strong></Link></p>
                        <p>We are 100% sure that if you follow the program you or your child will get the grade they want - <Link to={'/gaurantee'}><strong>see our A* - A guarantee.</strong></Link></p>
                        <p>If you have any unanswered questions check out our <Link to={'/faq'}><strong>FAQ page</strong></Link> or email us at <a href={'mailTo:hello@quicksense.org'}><strong>hello@quicksense.org</strong></a></p>
                        <p>{`We've`} also created a comprehensive {`'Learning how to Learn'`} guide which will cover in detail all the strategies and techniques we know about learning and how to apply them (to all your subjects).</p>
                        <p>We will be releasing this early and for free to anyone who signs up to our email list. Fill out the <Link to={'/contact'}><strong>contact form</strong></Link> if {`you're`} interested</p>
                    </Col>
                </Section>
                <GetStarted description={'Try it for yourself. No credit card info is required until you\'re ready to buy. If you\'re ready to secure your A* then you unlock the rest of the course by upgrading to the paid version in the app. '}/>
            </Layout>
        </>
    }
}

export default AboutPage